// gallery
$(function() {
    let $gallery = $('.gallery');
    let $galleryTabs = $gallery.find($('.gallery-tabs'));
    let $galleryTabsButtonFilter = $galleryTabs.find($('.gallery-tabs__button-filter'));
    let $galleryTabsList = $galleryTabs.find($('.gallery-tabs__list')).hide();
    let $galleryTabsListItem = $galleryTabs.find($('.gallery-tabs__list-item--enabled'));
    let $galleryContent = $gallery.find($('.gallery__content'));
    let $galleryList = $gallery.find($('.gallery__list'));
    let $galleryListItem = $galleryList.find($('.gallery__list-item'));

    let image = [
        // ['image, 'thumbnail ', 'description', filter]
        ['img/photo/photo--101--1000x662.jpg', 'img/photo/photo--101--1000x662.jpg', 'Foto: Marek Vybíral', 'Marek'],
        ['img/photo/photo--102--1000x662.jpg', 'img/photo/photo--102--1000x662.jpg', 'Foto: Marek Vybíral', 'Marek'],
        ['img/photo/photo--103--1000x662.jpg', 'img/photo/photo--103--1000x662.jpg', 'Foto: Marek Vybíral', 'Marek'],
        ['img/photo/photo--104--1000x662.jpg', 'img/photo/photo--104--1000x662.jpg', 'Foto: Marek Vybíral', 'Marek'],
        ['img/photo/photo--200--1000x662.jpg', 'img/photo/photo--200--1000x662.jpg', 'Foto: Luna Torr', 'Luna'],
        ['img/photo/photo--201p--1000x662.jpg', 'img/photo/photo--201p--1000x662.jpg', 'Foto: Luna Torr', 'Luna'],
        ['img/photo/photo--202--1000x662.jpg', 'img/photo/photo--202--1000x662.jpg', 'Foto: Luna Torr', 'Luna'],
        ['img/photo/photo--203--1000x662.jpg', 'img/photo/photo--203--1000x662.jpg', 'Foto: Luna Torr', 'Luna'],
        ['img/photo/photo--204--1000x662.jpg', 'img/photo/photo--204--1000x662.jpg', 'Foto: Luna Torr', 'Luna'],
        ['img/photo/photo--205--1000x662.jpg', 'img/photo/photo--205--1000x662.jpg', 'Foto: Luna Torr', 'Luna'],
        ['img/photo/photo--206--1000x662.jpg', 'img/photo/photo--206--1000x662.jpg', 'Foto: Luna Torr', 'Luna'],
        ['img/photo/photo--207p--1000x662.jpg', 'img/photo/photo--207p--1000x662.jpg', 'Foto: Luna Torr', 'Luna'],
        ['img/photo/photo--301p--1000x662.jpg', 'img/photo/photo--301p--1000x662.jpg', 'Foto: Adina Lahodová', 'Adina'],
        ['img/photo/photo--302--1000x662.jpg', 'img/photo/photo--302--1000x662.jpg', 'Foto: Adina Lahodová', 'Adina'],
        ['img/photo/photo--303--1000x662.jpg', 'img/photo/photo--303--1000x662.jpg', 'Foto: Adina Lahodová', 'Adina'],
        ['img/photo/photo--304--1000x662.jpg', 'img/photo/photo--304--1000x662.jpg', 'Foto: Adina Lahodová', 'Adina'],
        ['img/photo/photo--305--1000x662.jpg', 'img/photo/photo--305--1000x662.jpg', 'Foto: Adina Lahodová', 'Adina'],
        ['img/photo/photo--306p--1000x662.jpg', 'img/photo/photo--306--1000x662.jpg', 'Foto: Adina Lahodová', 'Adina'],
        ['img/photo/photo--307p--1000x662.jpg', 'img/photo/photo--307p--1000x662.jpg', 'Foto: Adina Lahodová', 'Adina'],
        ['img/photo/photo--308p--1000x662.jpg', 'img/photo/photo--308--1000x662.jpg', 'Foto: Adina Lahodová', 'Adina']
    ];

    let $galleryItem = [];
    let filter = 'all';
    let filterShowByDefault = false;

    let actualViewportWidth = window.innerWidth;
    let desktopViewportWidth = 992;

    let mobileViewportFlag = false;
    let desktopViewportFlag = false;

    if (filterShowByDefault) {
        $galleryTabsButtonFilter.toggleClass('gallery-tabs__button-filter--active');
        $galleryTabsList.slideToggle();
    }

    $(window).resize(function() {

        actualViewportWidth = window.innerWidth;

        // mobile viewport
        if ((actualViewportWidth < desktopViewportWidth) && !mobileViewportFlag) {
            mobileViewportFlag = true;
            desktopViewportFlag = false;

            initGallery();
        }
        // desktop viewport
        else if ((actualViewportWidth > desktopViewportWidth) && !desktopViewportFlag) {
            desktopViewportFlag = true;
            mobileViewportFlag = false;

            initGallery();
        }
    }).resize();

    // click tabs button filter
    $galleryTabsButtonFilter.click(function() {
        $galleryTabsList = $(this).closest('.gallery-tabs').find('.gallery-tabs__list');

        $(this).toggleClass('gallery-tabs__button-filter--active');
        $galleryTabsList.slideToggle();

        return false;
    });

    // click tabs item
    $galleryTabsListItem.click(function() {
        if ($(this).hasClass('gallery-tabs__list-item--active')) return;

        filter = $(this).data('filter');
        initGallery();

        return false;
    });

    // create gallery
    function createGallery() {
        $galleryItem = [];

        // gallery items
        let orderIndex = 0;
        for (let i = 0; i < image.length; i++) {
            if (filter == 'all' || filter == image[i][3]) {
                $galleryItem.push($('<a class="gallery__list-item-link" href="' + image[i][0] + '" data-fancybox="gallery" data-caption="' + image[i][2] + '" data-order="' + orderIndex + '" title="' + image[i][2] + '"><img src="' + image[i][1] + '" /></a>'));
                orderIndex++;
            }
        }

        // add gallery items to markup
        let n = 0;
        for (let i = 0; i < $galleryItem.length; i++) {
            $($galleryListItem[n]).append($galleryItem[i]);

            if (mobileViewportFlag) {
                n = n + 2;
                if (n > 3) n = 0;
            } else if (desktopViewportFlag) {
                n++;
                if (n > 3) n = 0;
            }
        }

        // open animation
        $galleryContent.hide().animate({
            height: 'show'
        }, 'slow', 'easeInOutExpo');
    }

    // reorder gallery
    function reorderGallery() {
        let $galleryListItemLink = $('.gallery__list-item-link');

        // sort array of objects by a property
        let sortObjectsBy = function(field, reverse, primer) {
            let key = primer ? function(x) {
                return primer(x[field])
            } : function(x) {
            return x[field]
            };
            reverse = !reverse ? 1 : -1;
            return function(a, b) {
                return a = key(a),
                b = key(b),
                reverse * ((a > b) - (b > a));
            }
        }

        // click handler
        $galleryListItemLink.on('click', function() {
            let fancyboxItem = [];

            // create array of fancyBox objects
            $galleryListItemLink.each(function() {
                let el = $(this).get(0);
                let order = $(this).data('order');

                fancyboxItem.push({
                    src     : el.href,
                    caption : el.title,
                    order   : order
                });
            });

            // sort the fancybox array of objects by the "order" property
            fancyboxItem.sort(sortObjectsBy('order', false, function(a) {
                return a;
            }));
 
            // launch fancybox programmatically
            Fancybox.show(
                fancyboxItem,
                {
                    //startIndex: $(this).data('order') // start gallery from the clicked element
                    startIndex: this.dataset.order // start gallery from the clicked element
                }
            );

            return false;
        });
    }

    // remove gallery
    function removeGallery() {
        for (let i = 0; i < $galleryItem.length; i++) {
            $galleryItem[i].remove();
        }
    }

    // init tabs
    function initTabs() {
        $galleryTabsListItem.each(function() {
            $(this).removeClass('gallery-tabs__list-item--active');

            if (filter == $(this).data('filter')) {
                $(this).addClass('gallery-tabs__list-item--active');
            }
        });
    }

    // init gallery
    function initGallery() {
        initTabs();

        // close animation
        $galleryContent.animate({
            height: 'hide'
        }, 'slow', 'easeInOutExpo', function() {
            // animation complete
            removeGallery();
            createGallery();
            reorderGallery();
        });
    }
});
